<template>
    <div class="page-reconciliation-more">
        <MenuTabList
            :title="kitTitle" 
            routePathPrev="/reconciliation/offers"
            class="page-reconciliation-more__menu-tab-list"
        />
        <PageReconciliationApproved 
            :kitId="kitId"
            pageType="more"
        />
    </div>
</template>

<script>
import PageReconciliationApproved from './PageReconciliationApproved.vue';
import MenuTabList from '../components/MenuTabList/MenuTabList.vue';
export default {
    name: 'PageReconciliationMore',
    components: {
        PageReconciliationApproved,
        MenuTabList
    },
    props: {
        kitId: {
            type: String,
            default: ''
        },
        kitTitle: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-reconciliation-more {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        height: 100%;
        margin: 0 -40px -40px -40px;
        padding: 0 40px 40px 40px;

        &__menu-tab-list {
            // margin-top: 30px;

            background: #fff;
            margin: 0 -40px 0 -40px;
            padding: 30px 40px 0 40px;
        }
    }
</style>